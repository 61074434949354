import dayjs from 'dayjs'
import dayjsTimezone from 'dayjs/plugin/timezone'
import dayjsUtc from 'dayjs/plugin/utc'

import { DeliveryStatusType, Event } from '../../types'

dayjs.extend(dayjsUtc)
dayjs.extend(dayjsTimezone)

const europeLondonTz = 'Europe/London'

type HeaderCopies = {
  label: string | null
  title: string
  subtitle?: string
  badgeText?: string
}

/** Encapsulte logic for calculating label, title and subtitle */
export const getHeaderCopies = ({
  events,
  status,
  statusDisplayString,
  estimatedArrivalWindowEndAt,
  estimatedArrivalWindowStartAt,
  parcelDeliveredAt,
  parcelDeliveredAtDisplayString,
  timezone,
  expectedDeliveryTimeDisplayString,
}: {
  events: Event[]
  status?: DeliveryStatusType
  statusDisplayString: string
  estimatedArrivalWindowStartAt?: string | null
  estimatedArrivalWindowEndAt?: string | null
  parcelDeliveredAt?: string | null
  parcelDeliveredAtDisplayString?: string | null
  timezone?: string | null
  expectedDeliveryTimeDisplayString: string | null
}): HeaderCopies => {
  const isDelivered = status === DeliveryStatusType.Delivered

  const parcelDelivered =
    parcelDeliveredAtDisplayString ||
    dayjs(parcelDeliveredAt)
      .tz(timezone ?? europeLondonTz)
      .format('MMMM DD, HH:mm')

  const label = isDelivered
    ? parcelDelivered
    : events[events.length - 1].statusDisplaySlug
  const estimatedArrivalTime = getEstimatedArrivalTime(
    estimatedArrivalWindowStartAt,
    estimatedArrivalWindowEndAt,
    timezone
  )
  const fallbackTitle = isDelivered ? 'Delivered' : estimatedArrivalTime

  return {
    label,
    title: expectedDeliveryTimeDisplayString || fallbackTitle,
    subtitle: statusDisplayString,
  }
}

/**
 * Return a string with the time window, with am/pm. For example
 * `8:00-10:00`
 */
const getEstimatedArrivalTime = (
  startAt?: string | null,
  endAt?: string | null,
  timezone?: string | null
): string => {
  const formatString = 'HH:mm'

  if (!startAt && !endAt) {
    return ''
  }

  const formattedStartAt = dayjs(startAt)
    .tz(timezone ?? europeLondonTz)
    .format(formatString)
  const formattedEndAt = dayjs(endAt)
    .tz(timezone ?? europeLondonTz)
    .format(formatString)

  if (!startAt) {
    return formattedEndAt
  } else if (!endAt) {
    return formattedStartAt
  }

  return `${formattedStartAt}-${formattedEndAt}`
}

// Only export for testing purposes
export const getProgressValue = (status: DeliveryStatusType) => {
  switch (status) {
    case DeliveryStatusType.Delayed:
    case DeliveryStatusType.DeliveryFailedReturnToSender:
    case DeliveryStatusType.CollectionExpiredReturnToSender:
    case DeliveryStatusType.Lost:
    case DeliveryStatusType.Unknown:
      return 0
    case DeliveryStatusType.ParcelReceived:
    case DeliveryStatusType.OrderCreated:
    case DeliveryStatusType.ReceivedFromRetailer:
    case DeliveryStatusType.AwaitingDropOff:
      return 10
    case DeliveryStatusType.DeliveryFailedWillReattempt:
    case DeliveryStatusType.ReceivedAtPitstop:
    case DeliveryStatusType.ReattemptingDelivery:
    case DeliveryStatusType.Scheduled:
    case DeliveryStatusType.PreparingCustomerCollection:
      return 50
    case DeliveryStatusType.OutForDelivery:
    case DeliveryStatusType.InTransit:
    case DeliveryStatusType.HSCReceivedRTSParcel:
    case DeliveryStatusType.AwaitingCustomerCollection:
      return 70
    case DeliveryStatusType.Delivered:
    case DeliveryStatusType.Cancelled:
    case DeliveryStatusType.RTSParcelDispatched:
    case DeliveryStatusType.CollectedByCustomer:
      return 100
    default:
      return 0
  }
}

export const getBadgeColorByType = (type: 'info' | 'warn') => {
  switch (type) {
    case 'warn':
      return {
        bgColor: 'danger.500',
        color: 'relay.textPrimary',
      }
    case 'info':
    default:
      return {
        bgColor: 'relay.moss',
        color: 'relay.black',
      }
  }
}
