export enum OrderType {
  Shipment = 'Shipment',
  Return = 'Return',
}

export enum DeliveryStatusType {
  AwaitingDropOff = 'awaiting_drop_off',
  ReceivedAtPitstop = 'received_at_pitstop',
  HSCReceivedRTSParcel = 'hsc_received_rts_parcel',
  RTSParcelDispatched = 'rts_parcel_dispatched',
  InTransit = 'in_transit',
  OrderCreated = 'order_created',
  ReceivedFromRetailer = 'received_from_retailer',
  ParcelReceived = 'parcel_received',
  OutForDelivery = 'out_for_delivery',
  ReattemptingDelivery = 'reattempting_delivery',
  Scheduled = 'scheduled',
  Delivered = 'delivered',
  Delayed = 'delayed',
  DeliveryFailedWillReattempt = 'delivery_failed_will_reattempt',
  DeliveryFailedReturnToSender = 'delivery_failed_return_to_sender',
  Cancelled = 'cancelled',
  Lost = 'lost',
  CollectedByCustomer = 'collected_by_customer',
  PreparingCustomerCollection = 'preparing_customer_collection',
  AwaitingCustomerCollection = 'awaiting_customer_collection',
  CollectionExpiredReturnToSender = 'collection_expired_return_to_sender',
  Unknown = 'unknown',
}

export const COLLECTION_SCHEDULED_STATUES = [
  DeliveryStatusType.PreparingCustomerCollection,
  DeliveryStatusType.AwaitingCustomerCollection,
  DeliveryStatusType.CollectionExpiredReturnToSender,
  DeliveryStatusType.CollectedByCustomer,
]

export type Coordinate = {
  lat: number
  lon: number
}

export type BadgeText = {
  label: string
  type: 'info' | 'warn'
}

export type Destination = {
  addressLine1: string
  addressLine2: string | null
  addressLine3: string | null
  addressLine4: string | null
  town: string
  postalCode: string
  countryCode: string
  county: string | null
  telephone: string
  email: string
  name: string
  /** Lat/Lon of the address */
  coordinates: Coordinate
}

export type Event = {
  status: DeliveryStatusType
  statusDisplaySlug: string
  timestamp: string
  /** @deprecated use images instead */
  imageUrl: string | null
  /** A list of image urls for the event */
  images?: string[] | null
  courierNote?: string | null
}

export type OrderItem = {
  name: string
  quantity: number
  brandName?: string | null
}

export enum SafePlaceType {
  Receptionist = 'receptionist',
  MailArea = 'mail_area',
  Neighbour = 'neighbour',
  OutsideDoorInBuilding = 'outside_door_in_building',
  EnclosedPorch = 'enclosed_porch',
  ParcelBox = 'parcel_box',
  Shed = 'shed',
  Backdoor = 'backdoor',
  Garage = 'garage',
}

export type SafePlace = {
  id: SafePlaceType
  label: string
}

export type SafePlacePreference = {
  action: 'set' | 'clear'
  safePlaceType: SafePlaceType
  /** The neighbour door number if it's the selected safe place */
  neighbourDoorNumber?: string | null
}

export type PitstopOpeningTime = {
  /** 1 for Monday, 7 for Sunday */
  dayOfWeek?: number
  opensAt?: string
  closesAt?: string
}

export type OrderData = {
  shipmentId?: string
  trackingNumber: string
  /**
   * The order number from the shipper or retailer (not relay number)
   */
  shipperOrderNumber?: string | null
  destination?: Destination
  events: Event[]
  /** The current status of the delivery */
  deliveryStatus: DeliveryStatusType
  /**
   * The text used to describe the current delivery status.
   * When we are ready, we can make this required
   */
  statusDisplayString: string
  /** The text to display in the badge, e.g. DELAYED */
  statusBadgeText?: string | null
  /**
   * The start time of the projected parcel arrival time range.
   * This could be null if the parcel is delayed
   */
  estimatedArrivalWindowStartAt?: string | null
  /**
   * The end time of the projected parcel arrival time range.
   * This could be null if the parcel is delayed
   */
  estimatedArrivalWindowEndAt?: string | null
  /**
   * The delivery display time string
   */
  expectedDeliveryTimeDisplayString: string | null
  /**
   * Actual parcel arrival time. It should be null unless the `status` is
   * delivered
   */
  parcelDeliveredAt?: string | null
  parcelDeliveredAtDisplayString?: string | null
  /**
   * The timezone in which the delivery will take place
   */
  timezone?: string | null
  /** Customer's note to the courier */
  courierNote?: string | null
  /** The items in the order */
  orderDetails?: OrderItem[]
  /** The name of the merchant where the order was placed */
  brandName?: string | null
  /** The logo image of the shipper */
  shipperLogo?: string

  /** The list of available safe places */
  safePlacesList?: SafePlace[]
  /** The preferred safe place by the user  */
  safePlacePreference?: SafePlacePreference

  /** Pistop related info */
  pitstopUid?: string
  pitstopName?: string
  pitstopOpeningTimes?: PitstopOpeningTime[]
  pitstopAddress?: string
  pitstopPostcode?: string
  pitstopGeoLat?: number
  pitstopGeoLong?: number

  contactPhoneNumber?: string

  featureFlagValues?: Record<string, string | boolean>
}

export type ReturnOrderData = Pick<
  OrderData,
  | 'trackingNumber'
  | 'expectedDeliveryTimeDisplayString'
  | 'events'
  | 'statusDisplayString'
  | 'deliveryStatus'
>

// Typeguard to make sure object is in the shape we expected it to be
export function isOrderDataType(data: unknown): data is OrderData {
  return Boolean(
    data &&
      typeof data === 'object' &&
      data !== null &&
      'shipmentId' in data &&
      'destination' in data &&
      'trackingNumber' in data &&
      'deliveryStatus' in data &&
      'events' in data
  )
}

export function isReturnDataType(data: unknown): data is ReturnOrderData {
  return Boolean(
    data &&
      typeof data === 'object' &&
      'trackingNumber' in data &&
      'expectedDeliveryTimeDisplayString' in data &&
      'events' in data &&
      'statusDisplayString' in data &&
      'deliveryStatus' in data
  )
}
