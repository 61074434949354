import { Badge, Progress, Text } from 'native-base'
import { HStack, VStack } from 'native-base'
import React from 'react'

import { BadgeText } from '../../types'
import { getBadgeColorByType } from './headerUtils'
import Logo from './Logo'

type HeaderProps = {
  label: string | null
  title: string
  subtitle?: string
  showProgressBar?: boolean
  progressBarValue?: number
  badgeText?: BadgeText
  renderBeforeProgressBar?: () => JSX.Element
}

/**
 * Header for order tracking page with delivery status and progress bar
 */
function Header({
  label,
  title,
  subtitle,
  showProgressBar = true,
  progressBarValue,
  badgeText,
  renderBeforeProgressBar,
}: HeaderProps) {
  return (
    <VStack space={3}>
      <HStack alignItems={'center'} space={2}>
        <VStack flex={'1'} pr={2}>
          {badgeText && badgeText.label && (
            <Badge
              bgColor={getBadgeColorByType(badgeText.type).bgColor}
              alignSelf={'flex-start'}
              marginBottom={3}
              _text={{
                fontWeight: 'bold',
                color: getBadgeColorByType(badgeText.type).color,
              }}
            >
              {badgeText.label}
            </Badge>
          )}
          {label && (
            <Text fontSize={'sm'} opacity={50} fontWeight={'bold'}>
              {label}
            </Text>
          )}
          <Text fontSize={'4xl'} fontWeight={'extrabold'} lineHeight={'sm'}>
            {title}
          </Text>
        </VStack>
        <Logo />
      </HStack>
      {subtitle && <Text fontWeight={'bold'}>{subtitle}</Text>}
      {renderBeforeProgressBar?.()}
      {showProgressBar && (
        <Progress
          testID={`HeaderProgressBar-${progressBarValue ?? 0}`}
          size={'xs'}
          value={progressBarValue ?? 0}
          bg={'relay.slate'}
          _filledTrack={{
            bg: 'relay.volt',
          }}
        />
      )}
    </VStack>
  )
}

export default Header
