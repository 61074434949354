/* eslint-disable react/no-children-prop */
import parsePhoneNumber, { isValidPhoneNumber } from 'libphonenumber-js'
import {
  Button,
  FormControl,
  Input,
  InputGroup,
  InputLeftAddon,
  Modal,
  Text,
  VStack,
} from 'native-base'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { useBackendFeatureFlagsContext } from '../../../../../core/FeatureFlag'
import courierImg from '../../../../../images/courier.png'
import { useOrderPageContext } from '../../../OrderPageContext'
import useSavePhoneNumber from '../useSavePhoneNumber'
import getLocalStorageNumber from './getLocalStorageNumber'
import Logo from './Logo'

const LOCAL_STORAGE_KEY = 'relay-customer-portal-phone-number-modal'
// the max number we allow the modal to be shown to the same customer
const MAX_TIMES_SEEN = 3
const COUNTRY_CODE = 'GB'

const COURIER_IMAGE_SIZE = 240

const StyledCourierImg = styled.img`
  margin-top: 24px;
  width: ${COURIER_IMAGE_SIZE}px;
  height: ${COURIER_IMAGE_SIZE}px;
`

const incrementTimesShown = (localStorageKey: string) => {
  const currentTimes = getLocalStorageNumber(localStorageKey)
  localStorage.setItem(localStorageKey, `${currentTimes + 1}`)
}

type Props = {
  onSuccess: () => void
}

/**
 * Prompt customer to register their phone number with a modal on page load
 */
function PhoneNumberModal({ onSuccess }: Props) {
  const {
    orderData: { brandName, trackingNumber, destination, contactPhoneNumber },
    updateOrderData,
  } = useOrderPageContext()
  const { customerPortalShowPhoneNumberPrompt } =
    useBackendFeatureFlagsContext()
  const { loading, error, setError, savePhoneNumber } = useSavePhoneNumber()

  const [showModal, setShowModal] = useState<boolean>(false)
  const [phoneNumber, setPhoneNumber] = useState<string>('')
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState<boolean>(true)

  const localStorageKeyByShipment = `${LOCAL_STORAGE_KEY}-${trackingNumber}`

  const hasPhoneNumber = !!contactPhoneNumber
  const handleClose = () => {
    setShowModal(false)
    incrementTimesShown(localStorageKeyByShipment)
  }

  const handleSavePhoneNumber = async () => {
    if (!isValidPhoneNumber(phoneNumber, COUNTRY_CODE)) {
      setIsPhoneNumberValid(false)
      return
    }

    setIsPhoneNumberValid(true)
    const parsedPhoneNumber = parsePhoneNumber(phoneNumber, COUNTRY_CODE)

    if (!parsedPhoneNumber?.number || !parsedPhoneNumber.nationalNumber) {
      setError('Phone number is invalid')
      return
    }

    savePhoneNumber(parsedPhoneNumber.number, (contactPhoneNumber?: string) => {
      if (destination) {
        updateOrderData('destination', {
          ...destination,
          contactPhoneNumber,
        })
      }

      setShowModal(false)
      incrementTimesShown(localStorageKeyByShipment)
      onSuccess()
    })
  }

  useEffect(() => {
    const timesSeen = getLocalStorageNumber(localStorageKeyByShipment)
    if (
      customerPortalShowPhoneNumberPrompt &&
      !hasPhoneNumber &&
      timesSeen < MAX_TIMES_SEEN
    ) {
      setShowModal(true)
    }
  }, [
    localStorageKeyByShipment,
    hasPhoneNumber,
    customerPortalShowPhoneNumberPrompt,
  ])

  return (
    <Modal
      isOpen={showModal}
      onClose={handleClose}
      size={['full', 'full', '650px']}
      closeOnOverlayClick={false}
      margin={'auto'}
    >
      <Modal.Content
        width={'100%'}
        height={['100%', '100%', 'auto']}
        maxHeight={['full', 'full', '700px']}
        testID={'yoyo'}
        margin={0}
      >
        <Logo />
        <Modal.CloseButton />
        <Modal.Body>
          <VStack space={8} alignItems={'center'}>
            <StyledCourierImg src={courierImg} />
            <VStack space={2} alignItems={'center'}>
              <Text textAlign={'center'}>
                Help us deliver your {brandName} parcel successfully
              </Text>
              <Text>Please add a phone number</Text>
            </VStack>

            <FormControl isInvalid={!isPhoneNumberValid}>
              <InputGroup variant={'filled'} width={'100%'}>
                <InputLeftAddon px={3} children={'+44'} />
                <Input
                  type={'text'}
                  size={'2xl'}
                  placeholder={'Phone number'}
                  value={phoneNumber}
                  onChangeText={(text: string) => setPhoneNumber(text)}
                  focusOutlineColor={'relay.volt'}
                  fontSize={'md'}
                  _hover={{
                    borderColor: 'relay.volt',
                  }}
                  flex={'1'}
                  isDisabled={loading}
                />
              </InputGroup>
              <FormControl.ErrorMessage>
                {error || 'You must enter a phone number'}
              </FormControl.ErrorMessage>
              <Button
                bgColor={'relay.volt'}
                borderRadius={'full'}
                _text={{
                  color: 'relay.black',
                }}
                width={'100%'}
                mt={8}
                onPress={handleSavePhoneNumber}
                isLoading={loading}
              >
                Add phone number
              </Button>
            </FormControl>
            <Text
              width={'310px'}
              textAlign={'center'}
              fontSize={'xs'}
              lineHeight={'sm'}
            >
              We will only use this phone number in the event we need to contact
              you about your deliveries.
            </Text>
          </VStack>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  )
}

export default PhoneNumberModal
